<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required ,maxLength} from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";

export default {

    page: {
        title: "Unit",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { },
    props:{
      selectedRow : null,
     room: {
      type: Object,
      default: null,
    }
    },
  data() {
    return {
      title: "Nouveau Type de Unit",
      disabled : true,
      canDelte : false,
      typeForm: {
        name: "",
        description: "",
        type: "",
        uuid: ""
      },
       classifications: [
        {
          id: 'vip',
          name: 'VIP',
        },
        {
          id: 'ordinary',
          name: 'Ordinnaire',
        },
      ],
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      
    };
  },
  validations: {
    typeForm: {
      name: { required, maxLength: maxLength(64) },
    },
  },

  watch:{
    selectedRow(val){
      this.clearFrom();
        this.canDelte                = false;
      if(val.length > 0 && undefined != val[0]){
        this.typeForm.name           = val[0].designation;
        this.typeForm.uuid           = val[0].uuid ;
        this.canDelte                = true;
      }
    }  
  },
  mounted(){

},

 methods:{
    clearFrom(){
      this.typeForm.name           = "";
      this.typeForm.description    = "";
      this.typeForm.classification = "" ;
      this.typeForm.uuid           = "" ;
    },
    addBtn(){
      this.disabled = false;
      this.clearFrom();
    },
    duplicateBtn(){
      this.disabled = false;
      this.typeForm.uuid = "";
    },
    editBtn(){
      this.disabled = false;
    },
    cancelBtn(){
      this.disabled = true;
    },
    formSubmit() {
       
      this.submitted = true;
      var valid = true;
      // var validFormData = this._validateContractAmnts();

      if(valid){
      this.$http
        .post("/infrastructure/unit_types/store", this.typeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");   
              this.$emit('realodTable',true)           
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
    deleteTypeRoom(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/unit_types/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit('realodTable',true)    
                  this.clearFrom()
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }
    
}
</script>
<style scoped>
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #Sticky {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pill{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50%;
  }
  .btn-add{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicate{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-edit{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #1CA68D ;
  }
  .btn-delete{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-upload{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-download{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-print{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <ul id="Sticky">
              <li>
                <button type="button" @click="addBtn" class="btn btn-add rounded-pill float-right mr-1 mt-1">
                <i class="fas fa-plus text-white"></i>
                </button>
              </li>
              <li>
                <button type="button" @click="duplicateBtn" class="btn btn-duplicate rounded-pill float-right mr-1 mt-1">
                <i class="far fa-copy text-white"></i>
                </button>
              </li>
              <li>
                <button type="button" @click="editBtn" class="btn btn-edit rounded-pill float-right mr-1 mt-1">
                <i class="far fa-edit text-white"></i>
                </button>
              </li>
              <li>
                <button type="button" :disabled="!canDelte" @click="deleteTypeRoom(typeForm.name,typeForm.uuid)" class="btn btn-delete rounded-pill float-right mr-1 mt-1">
                <i class="far fa-trash-alt text-white"></i>
                </button>
              </li>
              <li>
                <button type="button"  class="d-none btn btn-upload rounded-pill float-right mr-1 mt-1">
                <i class="fas fa-file-upload  text-white"></i>
                </button>
              </li>
              <li>
                <button type="button"  class="d-none btn btn-download rounded-pill float-right mr-1 mt-1">
                <i class="fas fa-file-download text-white"></i>
                </button>
              </li>
              <li>
                <button type="button"  class="d-none btn btn-print rounded-pill float-right mr-1 mt-1">
                <i class="fas fa-print"></i>
                </button>
              </li>
            </ul>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label> 
                    <button
                      class="btn btn-primary float-right mb-2 mr-5"
                      type="submit"
                      v-show="!disabled"
                    >
                      Enregistrer
                    </button>
                    <input
                    :disabled="disabled"
                      id="projectname"
                      v-model="typeForm.name"
                      type="text"
                      class="form-control"
                      required
                      placeholder=""
                    />
                  </div>
                </div>

              </div>

              <div>
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
